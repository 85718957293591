import { createContext, useEffect, useRef, useState } from "react"
import { t } from 'i18next'

const SchoolContext = createContext()

export default SchoolContext

/**
    ** Бүрэлдэхүүн сургуулийн id-г авах context
    * @param {String}           setSchool           Сонгосон сургуулийн id-г авна
    * @param {String}           school_id           Тухайн сургуулийн id
    * @param {String}           schoolName          Сонгосон сургуулийн нэр-г авна
    * @param {String}           setSchoolName       Сонгосон сургуулийн нэр
*/

export const SchoolContextProvider = ({ children }) => {

    const [school_id, setSchool] = useState(() => sessionStorage.getItem("school_id") || '')
    const [schoolName, setSchoolName] = useState(t('Бүх сургууль'))
    const [parentschoolName, setParentSchoolName] = useState(t('Бүх сургууль'))
    const isFirstRender = useRef(true)

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false
            return
        }

        sessionStorage.setItem("school_id", school_id)
    }, [school_id])

    return (
        <SchoolContext.Provider value={{ school_id, setSchool, schoolName, setSchoolName, parentschoolName, setParentSchoolName }}>
            {children}
        </SchoolContext.Provider>
    )
}
